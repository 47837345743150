import prettyBytes from 'pretty-bytes';
import React, { useEffect } from 'react';
import { Filename } from './Filename';

export const UploadedState = ({
  fileName,
  sizeInBytes,
  clearFromQueue,
}: {
  fileName: string;
  sizeInBytes: number;
  clearFromQueue: () => void;
}) => {
  useEffect(() => {
    const timeout = setTimeout(() => clearFromQueue(), 5000);
    return () => {
      clearTimeout(timeout);
    };
  });
  return (
    <div className="bg-lvl2 py-4 d-flex align-items-center">
      <svg className="text-info px-4" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.817 50">
        <g transform="translate(-878 -148)">
          <path
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="2px"
            d="M0,2.515V48H39.817V16.4L23.415,0H0Z"
            transform="translate(879 149)"
          ></path>
          <g transform="translate(887 163)">
            <path fill="none" d="M0,0H24V24H0Z"></path>
            <path fill="#fff" d="M9,16.17,4.83,12,3.41,13.41,9,19,21,7,19.59,5.59Z"></path>
          </g>
        </g>
      </svg>
      <div className="ps-3 flex-grow-1 text-info" style={{ maxWidth: 'calc(100% - 180px)' }}>
        <div className="d-flex justify-content-between flex-column">
          <Filename filename={fileName} />
          <span>{prettyBytes(sizeInBytes)}</span>
        </div>
      </div>
      <div>
        <button className="btn btn-lg btn-flat-light mx-4" onClick={clearFromQueue} aria-label="clear">
          <i className="icon">close</i>
        </button>
      </div>
    </div>
  );
};

import { QueryCache, QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any): boolean => error.statusCode >= 500 && failureCount <= 5,
    },
  },

  queryCache: new QueryCache({
    onError: (error: any): void => {
      throw new Error(error);
    },
  }),
});
import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Filename } from './Filename';

export const FailedState = ({
  fileName,
  isPartialyCompleted,
  errorDetail = 'Failed',
  clearFromQueue,
  retryFile,
  continueFileUpload,
  resetFileUpload,
}: {
  fileName: string;
  isPartialyCompleted: boolean;
  errorDetail: string;
  clearFromQueue: () => void;
  retryFile: () => void;
  continueFileUpload: () => void;
  resetFileUpload: () => void;
}) => {
  const resetButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="bg-lvl2 py-4 d-flex align-items-center justify-content-between text-danger">
      <svg className="col-1 mx-3" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.817 50">
        <g transform="translate(-418 -296)">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2px"
            d="M0,2.515V48H39.817V16.4L23.415,0H0Z"
            transform="translate(419 297)"
          ></path>
          <g transform="translate(429 310)">
            <path fill="none" d="M0,0H20V20H0Z"></path>
            <circle fill="currentColor" cx="12" cy="19" r="2" transform="translate(-1.667 0.667)" />
            <path fill="currentColor" d="M10 3h4v12h-4z" transform="translate(-1.667 0.667)" />
          </g>
        </g>
      </svg>
      <Filename filename={fileName} additionalCss="col-2 me-3" />

      {isPartialyCompleted && (
        <span className="col text-center me-3">
          This document has already a content of same mimetype partially uploaded. <br />
          <button className="btn btn-secondary mt-1 mx-4" onClick={continueFileUpload} aria-label="continue">
            Continue the previous upload
          </button>
          <button
            className="btn btn-secondary mt-1 mx-4"
            onClick={resetFileUpload}
            aria-label="reset"
            ref={resetButtonRef}
          >
            Reset the upload
          </button>
          <UncontrolledTooltip placement="top" target={resetButtonRef}>
            Pick this option if you are uploading a different content than the previous one
          </UncontrolledTooltip>
        </span>
      )}
      {!isPartialyCompleted && (
        <>
          <span className="col text-center me-3">
            {errorDetail}
          </span>
          <button className="col-1 btn btn-md btn-flat-danger" onClick={retryFile} aria-label="retry">
            <i className="icon">replay</i>
          </button>
        </>
      )}
      <button className="col-1 btn btn-md btn-flat-light me-3" onClick={clearFromQueue} aria-label="clear">
        <i className="icon">close</i>
      </button>
    </div>
  );
};

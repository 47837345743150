import { Content, uploadContent, Problem } from '@sgdocs/client';
import { QueryStatus, useMutation } from 'react-query';
import { useCallback, useEffect, useReducer } from 'react';
import { getType } from 'mime';
import { DocumentsFilesWithState } from '../SGMDocsUpload';
import {
  MAX_NETWORK_BUDGET,
  onNewFilesReceived,
  UploadQueueItem,
  uploadQueueReducer,
} from '../reducer/UploadQueueReducer';
import { EWidgetsSgmDocs } from '../../common/widget/enum';

const computeMimeType = (file: File): string => {
  const mimeType = getType(file.name);
  return mimeType !== null ? mimeType : 'application/octet-stream';
};

export const useUploadQueue = (
  baseUrl: string,
  fileChunkMinLength: number,
  authorizationHeaderProvider: () => Promise<string>,
  onResetFileUploadFailed?: (fileId: string, reason: any) => void,
  onFilesEnqueued?: () => void,
  onUploadCompleted?: (newContentCreated: Content) => void,
  onUploadFailed?: (failureFile: { documentId: string; workspaceId: string; reason: any; file: File }) => void
) => {
  const { mutate: resetUpload, status: resetUploadStatus, reset: resetResetUpload } = useMutation(uploadContent);
  const { mutate: uploadChunkThread1, status: uploadChunkThread1Status, reset: resetThread1 } = useMutation(uploadContent);
  const [{ queue: uploadQueueState, uploadings }, dispatch] = useReducer(uploadQueueReducer, {
    queue: [],
    uploadings: Array(MAX_NETWORK_BUDGET).fill(undefined),
    fileChunkMinLength,
  });

  const uploadingHash = uploadings
    .map((uploading) => (uploading ? uploading.id + uploading.start : 'undefined'))
    .join(',');
  const resetThreads = [resetThread1];

  const threadStatus: QueryStatus[] = [
    uploadChunkThread1Status
  ];
  const threads = [uploadChunkThread1];
  useEffect(() => {
    uploadings.forEach((uploading, threadIndex) => {
      if (
        uploading &&
        !uploading.isBeingProcessed &&
        (threadStatus[threadIndex] === 'idle' ||
          threadStatus[threadIndex] === 'error' ||
          threadStatus[threadIndex] === 'success')
      ) {
        dispatch({
          type: 'UPLOAD_CHUNK_BEING_PROCESSED_BY_A_THREAD',
          uploading,
          index: threadIndex,
        });
        authorizationHeaderProvider()
          .then((authorizationHeader) => {
            const chunkPayload: any = {
              authorizationHeader,
              documentId: uploading.documentId,
              workspaceId: uploading.workspaceId,
              file: uploading.file.slice(uploading.start, uploading.end, uploading.file.type),
              fileName: uploading.file.name,
              contentLength: uploading.size,
              contentType: computeMimeType(uploading.file),
              baseUrl,
              rightId: uploading.rightId,
              component: EWidgetsSgmDocs.UPLOAD
            };

            if (!(uploading.start === 0 && uploading.end === uploading.file.size)) {
              chunkPayload['contentRange'] = `bytes ${uploading.start}-${uploading.end - 1}/${uploading.file.size}`;
            }

            threads[threadIndex](
              chunkPayload,
              {
                onSuccess: (data: any) => {
                  dispatch({
                    type: 'UPLOAD_CHUNK_COMPLETED',
                    uploading,
                    index: threadIndex,
                  });
                },
                onSettled: (data: any) => {
                  if (data && (data as Content).documentVersionIdentifier && onUploadCompleted) {
                    onUploadCompleted(data as Content);
                  }
                },
                onError: (error: any) => {
                  dispatch({
                    type: 'UPLOAD_CHUNK_FAILED',
                    uploading,
                    index: threadIndex,
                    error: error as Problem,
                  });
                  if (onUploadFailed) {
                    onUploadFailed({
                      documentId: uploading.documentId,
                      workspaceId: uploading.workspaceId,
                      reason: error,
                      file: uploading.file,
                    });
                  }
                }
              }
            );
          })
          .catch((error) => {
            dispatch({
              type: 'UPLOAD_CHUNK_FAILED',
              uploading,
              index: threadIndex,
              error,
            });
            if (onUploadFailed) {
              onUploadFailed({
                documentId: uploading.documentId,
                workspaceId: uploading.workspaceId,
                reason: error,
                file: uploading.file,
              });
            }
          });
      }
    });
    return () => {
      resetThread1();
      resetResetUpload();
    };
  }, [uploadingHash]);
  const clearFileFromQueue = useCallback(
    (fileId: string) => {
      const busyThreadsForTheFileId = uploadings.flatMap((uploading, index) => {
        if (uploading?.id === fileId) {
          return [index];
        }
        return [];
      });
      busyThreadsForTheFileId.forEach((threadId) => resetThreads[threadId]());
      dispatch({ type: 'CLEAR_FILE', fileId });
    },
    [dispatch]
  );
  const retryFile = useCallback(
    (fileId: string) => {
      dispatch({ type: 'RETRY_FILE', fileId });
    },
    [dispatch]
  );
  const enqueueFilesToUpload = useCallback(
    (files: DocumentsFilesWithState) => {
      onNewFilesReceived(dispatch, files, onFilesEnqueued);
    },
    [dispatch, onFilesEnqueued]
  );

  const resetFileUpload = useCallback(
    (failedFile: UploadQueueItem) => {
      authorizationHeaderProvider().then((authorizationHeader) => {
        resetUpload(
          {
            authorizationHeader,
            documentId: failedFile.documentId,
            workspaceId: failedFile.workspaceId,
            contentRange: `reset`,
            contentType: computeMimeType(failedFile.file),
            baseUrl,
          },
          {
            onSuccess: () => {
              dispatch({ type: 'RESET_FILE_UPLOAD', fileId: failedFile.id });
            },
            onError: (reason: any) => {
              if (onResetFileUploadFailed) {
                onResetFileUploadFailed(failedFile.id, reason);
              }
            },
          }
        );
      });
    },
    [dispatch]
  );

  const continueFileUpload = useCallback(
    (fileId: string) => {
      dispatch({ type: 'CONTINUE_FILE_UPLOAD', fileId });
    },
    [dispatch]
  );

  return { uploadQueueState, enqueueFilesToUpload, clearFileFromQueue, retryFile, resetFileUpload, continueFileUpload };
};

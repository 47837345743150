import { widgetize, WidgetPropsMapping } from '@sg-widgets/react-core';
import { SGMDocsUpload } from './SGMDocsUpload';
import { EWidgetsSgmDocs } from '../common/widget/enum';

widgetize(
  EWidgetsSgmDocs.UPLOAD,
  SGMDocsUpload,
  {
    sgdocsBaseUrl: WidgetPropsMapping.asString({
      description: 'SGDocs base url',
    }),
    fileChunkMinLength: WidgetPropsMapping.asNumber({
      description: '[OPTIONAL] Minimum file size to use chunk facility in MB, defaults to 1 MB',
    }),
    onUploadCompleted: WidgetPropsMapping.asEventEmitter('upload-completed', {
      description: 'Event sent when the upload has completed, it contains created document information',
    }),
    onUploadFailed: WidgetPropsMapping.asEventEmitter('upload-failed', {
      description:
        'Event sent when the upload has failed, it contains file, workspaceId and documentId of the failed file',
    }),
    onResetFileUploadFailed: WidgetPropsMapping.asEventEmitter('reset-file-upload-failed', {
      description: 'Event sent when we failed to reset a file upload',
    }),
  },
  {
    exportReactAPI: {
      upload: 'Files to upload',
    },
  }
);

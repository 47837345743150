import React from 'react';
import { Filename } from './Filename';

export const UploadingState = ({
  fileName,
  clearFromQueue,
  uploadingProgress,
}: {
  fileName: string;
  clearFromQueue: () => void;
  uploadingProgress: number;
}) => {
  return (
    <div className="bg-lvl2 py-4 d-flex align-items-center">
      <svg className="px-4 text-info" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.817 50">
        <g transform="translate(-418 -296)">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2px"
            d="M0,2.515V48H39.817V16.4L23.415,0H0Z"
            transform="translate(419 297)"
          ></path>
          <g transform="translate(429 310)">
            <path fill="none" d="M0,0H20V20H0Z"></path>
            <path
              fill="currentColor"
              d="M4,10.667l1.175,1.175,4.658-4.65V17.333H11.5V7.192l4.65,4.658,1.183-1.183L10.667,4Z"
              transform="translate(-0.667 -0.667)"
            ></path>
          </g>
          <rect fill="currentColor" width="2" height="2" transform="translate(438 329)"></rect>
          <rect fill="currentColor" width="2" height="2" transform="translate(438 333)"></rect>
        </g>
      </svg>
      <div className="ps-3 flex-grow-1 text-info" style={{ maxWidth: 'calc(100% - 180px)' }}>
        <div className="d-flex justify-content-between">
          <Filename filename={fileName} />
          <span className="text-end text-secondary">{`Uploading (${uploadingProgress}%)`}</span>
        </div>
        <div className="upload-progress mt-2 bg-info-alpha-md">
          <div className="h-100 bg-info" style={{ width: uploadingProgress + '%' }}></div>
        </div>
      </div>
      <div>
        <button className="btn btn-lg btn-flat-light mx-4" onClick={clearFromQueue} aria-label="clear">
          <i className="icon">close</i>
        </button>
      </div>
    </div>
  );
};

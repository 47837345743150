/*
    /!\ DECLARATION OF WIDGET NAME
*/
export enum EWidgetsSgmDocs {
    COPY_DOCUMENT_FORM = 'sgm-docs-copy-document-form',
    CREATE_DOCUMENT_FORM = 'sgm-docs-create-document-form',
    DOCUMENT_CARD = 'sgm-docs-document-card',
    DOCUMENT_SORTING = 'sgm-docs-document-sorting',
    DOCUMENT_VIEWER = 'sgm-docs-document-viewer',
    DROP_ZONE = 'sgm-docs-drop-zone',
    SEARCH_FROM_MODEL = 'sgm-docs-search-from-model',
    UPLOAD = 'sgm-docs-upload'
}